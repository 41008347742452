@import url("https://fonts.googleapis.com/css?family=Poppins");

.card{
  width: 100% !important;
}
.align-start {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.do-you-know-header-text{
  color: $roman;
  font-size: 12px;
  font-weight: 600;
}
.do-you-know-text{
  color: $scorpion;
  font-size: 12px;
  font-weight: 400;
}
.align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.align-end {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.align-text-justify {
  text-align: justify !important;
}
.text-center-aligned {
  text-align: center !important;
}
.tooltip {
  position: absolute;
}

.correct-pass-key {
  color: $salem;
  font-size: 12px;
}

.wrong-pass-key {
  color: $rust;
  font-size: 12px;
}

.tooltip-inner {
  background: $white;
  border: 1px solid $rajah;
  color: $scorpion;
  font-weight: 500;
}

.tooltip.show .arrow::before {
  border-color: $rajah transparent $rajah transparent;
}

.alert-error {
  font-size: 16px;
  font-weight: 400;
  color: $outrageousorange;
}

.alert-success {
  font-size: 15px;
  font-weight: 500;
  color: $onyx;
}
.text-white {
  font-size: 15px;
  font-weight: 500;
  color: $white;
}

.card-header-border {
  background: $white;
  border-bottom: 1px solid $mintcream;
  border-radius: 1px;
  // box-shadow: 0px 1px 8px $whisper;
  color: $scorpion;
  font-weight: 500;
}

.campus-input {
  border: 1px solid $gainsborolight !important;
  background: $white !important;
  // border-bottom: 1px solid $gainsborolight !important;
  border-radius: 5px !important;
  // box-shadow: 0 3px 5px $gainsborolight;
  height: 50px !important;
  padding: 1;
  color: $scorpion;
  font-size: 15px;
  font-weight: 400;

  &:focus {
    box-shadow: none;
    border-color: $rajah !important;
    // box-shadow: 0 0 1px $outrageousorange;
    color: $scorpion;
    background-color: $white !important;
  }

  &:hover {
    border: 1px solid $rajah !important;
  }
}

.campus-input-oval {
  border: 1px solid $gainsborolight !important;
  background: $white !important;
  // border-bottom: 1px solid $gainsborolight !important;
  border-radius: 30px !important;
  // box-shadow: 0 3px 5px $gainsborolight;
  height: 50px !important;
  padding: 1;
  color: $scorpion;
  font-size: 15px;
  font-weight: 400;

  &:focus {
    box-shadow: none;
    border-color: $rajah !important;
    // box-shadow: 0 0 1px $outrageousorange;
    color: $scorpion;
    background-color: $white !important;
  }

  &:hover {
    border: 1px solid $rajah !important;
  }
}

.campus-input-oval-search {
  border: 1px solid $gainsborolight !important;
  background: $white !important;
  // border-bottom: 1px solid $gainsborolight !important;
  border-radius: 30px !important;
  // box-shadow: 0 3px 5px $gainsborolight;
  height: 40px !important;
  padding: 1;
  color: $scorpion;
  font-size: 15px;
  font-weight: 400;

  &:focus {
    box-shadow: none;
    border-color: $rajah !important;
    // box-shadow: 0 0 1px $outrageousorange;
    color: $scorpion;
    background-color: $white !important;
  }

  &:hover {
    border: 1px solid $rajah !important;
  }
}

.current-icon {
  color: $purpleheart;
  font-size: 10px;
}
.current-text {
  color: $purpleheart;
  font-size: 10px;
  font-weight: 700;
}

.campus-text-area {
  min-height: 80px !important;
  border: 1px solid $gainsborolight !important;
  background-color: $white !important;
  border-radius: 5px !important;
  padding: 1;
  color: $scorpion;
  font-size: 15px;
  font-weight: 400;

  &:focus {
    box-shadow: none;
    border-color: $rajah !important;
    color: $scorpion;
    background-color: $white !important;
  }

  &:hover {
    border: 1px solid $rajah !important;
  }
}

.campus-text-area-desc {
  min-height: 180px !important;
  border: 1px solid $gainsborolight !important;
  background-color: $white !important;
  border-radius: 5px !important;
  padding: 1;
  color: $scorpion;
  font-size: 15px;
  font-weight: 400;

  &:focus {
    box-shadow: none;
    border-color: $rajah !important;
    color: $scorpion;
    background-color: $white !important;
  }

  &:hover {
    border: 1px solid $rajah !important;
  }
}

.collapse-bg {
  background-color: $cccalendartoday;
  padding: 10px 5px;
  border-radius: 5px;
}

.collapse-icon {
  color: $trumpetflower;
}
.modal-header{
  padding: 0 !important;
}
.collapse-header {
  color: $davysgrey;
  font-size: 14px;
  font-weight: 400;
}

.org-header {
  color: $trumpetflower;
  font-size: 16px;
  font-weight: 700;
}

.form-collapse-card-header {
  background: $cccalendartoday;
  border: none;
  font-size: 14px;
  font-weight: 500;
  color: $trumpetflower;

  &:hover {
    background: $ivory;
  }
}

.mandate-star {
  color: $rust;
  margin: 1px
}
.hr-style{
  color: $bluebell;
}
.input-header-text {
  color: $bayofmany;
  font-size: 14px;
  font-weight: 400;
  margin: 3px;
}

.input-card-header-text {
  color: $onyx;
  font-size: 18px;
  font-weight: 500;
  margin: 3px;
}

.input-checkbox-text-grey {
  color: $onyx;
  font-size: 14px;
  font-weight: 400;
  margin: 3px;
}

.text-lite-grey {
  color: $suvagrey;
  font-size: 13px;
  font-weight: 400;
}

.campus-chip-box {
  padding: 2px 8px;
  margin: 5px;
  border: 1px solid $peachorange !important;
  border-radius: 35px !important;
  color: $scorpion;
  background-color: $white !important;
}
.dasboard-chip-box {
  padding: 4px;
  margin: 0;
  align-items: center;
  border: 1px solid $geyser !important;
  border-radius: 3px !important;
  color: $dimgray;
  font-size: 14px;
  font-weight: 500;
  background-color: $white !important;
  width: auto !important;
}
.campus-chip-text {
  font-size: 14px;
  font-weight: 400;
  color: $onyx;
}

.campus-chip-icon {
  font-size: 12px;
  color: $rajah;
  cursor: pointer;
  margin-left: 5px;

  &:hover {
    color: $coral !important;
  }
}

.campus-chip-plus-icon {
  color: $cerulean;
  cursor: pointer;

  &:hover {
    color: $easternblue !important;
  }
}

.campus-radio-box {
  color: $easternblue;
  background-color: $white;
  // height: 50px;
  width: auto;
  // max-width: 130px;
  padding: 6px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none !important;
  box-shadow: 0px 1px 1px $peachorange;

  &:focus {
    outline: 0;
    box-shadow: none !important;
    background: none !important;
  }
  &:focus:not(:hover) {
    outline: 0;
    box-shadow: none !important;
  }
  &:hover {
    background-image: linear-gradient(100deg,
    $cccalendartoday 10%,
    $peachorange 80%);
    color: $onyx !important;
  }
}
.campus-radio-box:focus:not(:hover) {
  background-color: red;
}

.campus-check-box {
  color: $trumpetflower;
  margin-top: 5px;
  outline: 1px solid $trumpetflower !important;
  border: 1px solid $white !important;
  box-shadow: 0 0 1px $trumpetflower;
  margin-right: 10px;
  width: 13px;
  height: 13px;
  border-radius: 1px !important;
}

.campus-check-box:checked {
  background-color: $trumpetflower !important;
  border: 1px solid $trumpetflower !important;
  box-shadow: 0 0 1px $trumpetflower;
}

.campus-check-box:focus,
.label::after,
.label.campus-check-box:focus,
.campus-check-box:checked::after,
.campus-check-box:not(.disabled):not(.disabled):active:focus {
  color: $trumpetflower;
  outline: 1px solid $trumpetflower !important;
  border: 1px solid $white !important;
  box-shadow: 0 0 1px $trumpetflower;
  // box-shadow: 0 0 0 .1rem  $white !important;
}

.campus-diffable-untick-text {
  font-size: 14px;
  font-weight: 600;
  color: $valencia;
}

.form-card {
  // padding: 5px 5px;
  border: 1px solid $solitude;
  box-shadow: 2px 5px 10px rgba(22, 24, 26, 0.07);
  border-radius: 5px;
}
.form-card-border {
  // padding: 5px 5px;
  border: 1px solid $hawkesblue;
  box-shadow: 2px 5px 10px rgba(22, 24, 26, 0.07);
  border-radius: 5px;
}

.form-card-lite {
  // padding: 5px 5px;
  border: 1px solid $mintcream;
  border-radius: 5px;
}

.form-card-header {
  background: $cccalendartoday;
  border: none;
  font-size: 14px;
  font-weight: 600;
  color: $davysgrey;
}

.form-card-footer {
  background: $white;
  border: none;
}

.form-card-border-none {
  border: none;
}

.form-card-shadow {
  padding: 2px 1px;
  border: 1px solid $whisper;
  box-shadow: 0px 6px 8px rgb(0 55 183 / 8%);
  border-radius: 8px;
}

.form-card-border-wide {
  padding: 5px 8px;
  background-color: $white;
  border: 1px solid $solitude;
  // box-shadow: 0px 6px 1px rgba(131, 132, 132, 0.08);
  border-radius: 10px;
}

.campus-radio-box-active {
  color: $white !important;
  background-image: linear-gradient(100deg,
    $cccalendartoday 20%,
    $peachorange 90%);
  // height: 50px;
  width: auto;
  // max-width: 130px;
  padding: 5px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid $isabelline !important;
  box-shadow: 0px 1px 1px $outrageousorange;
}

.cursor-pointer {
  cursor: pointer;
}

.loader-wdsinfotech {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $outrageousorange;
}

input[type=file]::file-selector-button:hover {
  border: none;
}

input[type=file].avatar {
  background: none !important;
  color: $oslogray;
  font-weight: 500;
}

.form-control:focus {
  box-shadow: none;
}

input[type=file]::file-selector-button {
  background-image: linear-gradient(10deg,
      $peachorange 10%,
      $cccalendartoday 100%);
  border: none !important;
  border-radius: 25px;
  margin: 1px 3px 2px 5px;
  padding: 5px 15px;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: $darkgreen !important;
  cursor: pointer;

  &:focus {
    outline: 0;
    box-shadow: none !important;
  }
  &:hover {
    background-image: linear-gradient(100deg,
        $cccalendartoday 10%,
        $peachorange 80%);
    color: $onyx !important;
  }
}

// .custom-date-picker::-webkit-calendar-picker-indicator {
//   color: $trumpetflower !important;
// }
// .custom-date-picker .react-datepicker-popper .react-datepicker__triangle {
//   color: $trumpetflower !important;
// }
// input[type=date]::-webkit-calendar-picker-indicator {
//   color: $trumpetflower !important;
// }

// dropdown menu changes

.dropdown-menu.show {
  // border: 1px solid $oasis;
  border: 1px solid $solitude;
  box-shadow: 2px 5px 10px rgba(22, 24, 26, 0.07);
  box-shadow: 0px 1px 1px $oasis;
  border-radius: 3px;
  font-size: 14px;
}

.dropdown-menu {
  background-image: linear-gradient(130deg,
  $cccalendartoday 80%,
  $peachorange 100%);
  color: $onyx !important;
  font-weight: 600 !important;
  margin: 5px -20px !important;
  padding: 1px 1px;
}
.dropdown-item {
  font-weight: 500;
  color: $scorpion;
  &:hover {
  background-image: linear-gradient(130deg,
  $peachorange 100%,
  $cccalendartoday 10%);
  }
}
.divider-dropdown{
  border: .01rem solid $oasis;
  margin: 8px 5px;
}
.button-icon[disabled] {
  background: transparent;
  color: $dimgray !important;
}
.button-transparent{
  background: transparent;
  border: none;
  cursor: pointer;
  &:focus {
    outline: 0;
    box-shadow: none !important;
    background: transparent !important;
  }
}
.button-icon {
  background: transparent;
  border: none;
  color: $easternblue !important;
  cursor: pointer;

  &:focus {
    outline: 0;
    box-shadow: none !important;
    background-color: $cccalendartoday !important;
  }

  &:hover {
    background-color: $cccalendartoday;
    color: $firebush !important;
  }
}
.button-icon-active {
  color: $firebush !important;
}
.button-icon-inactive {
  color: $bombay !important;
}
// .cert-image {
//   height:160px;
//   width:130px;
// }
// .cert-image:hover {
//   height:260px;
//   width:230px;
// }
.quest-menu-icon {
  color: $firebush;
}

.quest-menu-icon-size-sm {
  color: $firebush;
  font-size: 12px;
}

.mx-1 {
  margin: 0 5px;
}

.mx-2 {
  margin: 0 10px;
}

.mx-3 {
  margin: 0 1rem;
}
.m-1 {
  margin: 1rem;
}
.m-2 {
  margin: 2rem;
}
.mx-4 {
  margin: 0 2rem;
}

.mx-5 {
  margin: 0 3rem;
}

.ml {
  margin-left: .5rem;
}

.ml-1 {
  margin-left: 1rem;
}

.ml-2 {
  margin-left: 2rem;
}

.pl-1 {
  padding-left: 1rem;
}

.mp-1 {
  padding: 1rem;
}

.mp-2 {
  padding: 2rem;
}

.mptb {
  padding: .3rem 0;
}

.mptb-1 {
  padding: 1rem 0;
}

.mptb-2 {
  padding: 2rem 0 !important;
}

.mr-1 {
  margin-right: 1rem;
}

.mr-2 {
  margin-right: 2rem;
}

.mb {
  margin-bottom: 5px;
}
.mb-1 {
  margin-bottom: 10px;
}

.mt {
  margin-top: 5px;
}

.mt-1 {
  margin-top: 10px !important;
}

.mlr-2 {
  padding: 0 2rem;
}
.margin-zero {
  margin: 0 !important;
}
.padding-zero {
  padding: 0 !important;
}

/* Default styles */
.campus-media {
  font-size: 16px;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}

.nav-tabs {
  border-bottom: none !important;
}

/* Adjust for smaller screens */
@media only screen and (max-width: 768px) {
  .campus-media {
    font-size: 14px;
    max-width: 768px;
  }

  .campus-radio-box {
    width: 70px !important;
  }

  .campus-radio-box-active {
    width: 70px !important;
  }

  .display-data-none {
    display: none;
  }

  .margin-top-bottom {
    margin: .1rem 0 3rem 0 !important;
  }

  .margin-bottom {
    margin-bottom: 20px;
  }

  .margin-top-more {
    margin-top: 20px;
  }

  .nav-tabs {
    border-bottom: none !important;
  }
}

/* Adjust for even smaller screens */
@media only screen and (max-width: 576px) {
  .campus-media {
    font-size: 12px;
    max-width: 576px;
  }

  .campus-radio-box {
    width: 70px !important;
  }

  .campus-radio-box-active {
    width: 70px !important;
  }

  .display-data-none {
    display: none;
  }

  .margin-top-bottom {
    margin: .1rem 0 3rem 0 !important;
  }

  .margin-bottom {
    margin-bottom: 20px;
  }

  .margin-top-more {
    margin-top: 2.6rem !important;
  }

  .nav-tabs {
    border-bottom: none !important;
  }
}


// .campus-media {
//   width: 100%;
//   max-width: 1440px; /* Set the maximum width */
//   margin: 0 auto; /* Center the element horizontally */
// }

// /* Set the width for mobile screens */
// @media only screen and (max-width: 600px) {
//   .campus-media {
//     max-width: 540px; /* Adjust the maximum width */
//   }
// }

// /* Set the width for iPad screens */
// @media only screen and (min-width: 601px) and (max-width: 1024px) {
//   .campus-media {
//     max-width: 768px; /* Adjust the maximum width */
//   }
// }

// /* Set the width for laptop screens */
// @media only screen and (min-width: 1025px) and (max-width: 1440px) {
//   .campus-media {
//     max-width: 1000px; /* Adjust the maximum width */
//   }
// }

// /* Set the width for desktop screens */
// @media only screen and (min-width: 1441px) {
//   .campus-media {
//     max-width: 1440px; /* Adjust the maximum width */
//   }
// }

// /********** Template CSS **********/
// :root {
//     --primary: #355EFC;
//     --secondary: #E93C05;
//     --tertiary: #555555;
//     --light: #DFE4FD;
//     --dark: #011A41;
// }

// .back-to-top {
//     position: fixed;
//     display: none;
//     right: 30px;
//     bottom: 30px;
//     z-index: 99;
// }

// h1,
// .h1,
// h2,
// .h2,
// .fw-bold {
//     font-weight: 700 !important;
// }

// h3,
// .h3,
// h4,
// .h4,
// .fw-medium {
//     font-weight: 600 !important;
// }

// h5,
// .h5,
// h6,
// .h6,
// .fw-semi-bold {
//     font-weight: 500 !important;
// }


// /*** Spinner ***/
// #spinner {
//     opacity: 0;
//     visibility: hidden;
//     transition: opacity .5s ease-out, visibility 0s linear .5s;
//     z-index: 99999;
// }

// #spinner.show {
//     transition: opacity .5s ease-out, visibility 0s linear 0s;
//     visibility: visible;
//     opacity: 1;
// }


// /*** Button ***/
// .btn {
//     transition: .5s;
//     font-weight: 500;
// }

// .btn-primary,
// .btn-outline-primary:hover {
//     color: var(--light);
// }

// .btn-square {
//     width: 38px;
//     height: 38px;
// }

// .btn-sm-square {
//     width: 32px;
//     height: 32px;
// }

// .btn-lg-square {
//     width: 48px;
//     height: 48px;
// }

// .btn-square,
// .btn-sm-square,
// .btn-lg-square {
//     padding: 0;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     font-weight: normal;
// }


// /*** Header ***/
// .carousel-caption {
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     text-align: start;
//     z-index: 1;
// }

// .carousel-control-prev,
// .carousel-control-next {
//     width: 3rem;
// }

// .carousel-control-prev-icon,
// .carousel-control-next-icon {
//     width: 3rem;
//     height: 3rem;
//     background-color: var(--primary);
//     border: 10px solid var(--primary);
// }

// .carousel-control-prev-icon {
//     border-radius: 0 3rem 3rem 0;
// }

// .carousel-control-next-icon {
//     border-radius: 3rem 0 0 3rem;
// }

// @media (max-width: 768px) {
//     #header-carousel .carousel-item {
//         position: relative;
//         min-height: 450px;
//     }
    
//     #header-carousel .carousel-item img {
//         position: absolute;
//         width: 100%;
//         height: 100%;
//         object-fit: cover;
//     }
// }

// .page-header {
//     padding-top: 12rem;
//     padding-bottom: 6rem;
//     background: url(./../../../public/img/header.jpg) top left no-repeat;
//     background-size: cover;
// }

// .page-header .breadcrumb-item,
// .page-header .breadcrumb-item a {
//     font-weight: 500;
// }

// .page-header .breadcrumb-item+.breadcrumb-item::before {
//     color: var(--tertiary);
// }


// /*** Facts ***/
// .facts {
//     background: linear-gradient(rgba(53, 94, 252, .95), rgba(53, 94, 252, .95)), url(./../../../public/img/bg.png);
// }


// /*** Callback ***/
// .callback {
//     position: relative;
// }

// .callback::before {
//     position: absolute;
//     content: "";
//     width: 100%;
//     height: 50%;
//     top: 0;
//     left: 0;
//     background: linear-gradient(rgba(53, 94, 252, .95), rgba(53, 94, 252, .95)), url(./../../../public/img/bg.png);
//     z-index: -1;
// }


// /*** Feature ***/
// .feature .feature-box,
// .feature .feature-box * {
//     transition: .5s;
// }

// .feature .feature-box:hover {
//     background: var(--primary);
//     border-color: var(--primary) !important;
// }

// .feature .feature-box:hover * {
//     color: #FFFFFF !important;
// }


// /*** Service ***/
// .service .nav .nav-link {
//     transition: .5s;
// }

// .service .nav .nav-link.active {
//     border-color: var(--primary) !important;
//     background: var(--primary);
// }

// .service .nav .nav-link.active h5 {
//     color: #FFFFFF !important;
// }

// .service .nav .nav-link.active h5 i {
//     color: #FFFFFF !important;
// }


// /*** Project ***/
// .project-item,
// .project-item .project-img {
//     position: relative;
// }

// .project-item .project-img a {
//     position: absolute;
//     width: 100%;
//     height: 100%;
//     top: 0;
//     left: 0;
//     background: rgba(255, 255, 255, .5);
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     border-radius: 8px;
//     opacity: 0;
//     transition: .5s;
// }

// .project-item:hover .project-img a {
//     opacity: 1;
// }

// .project-item .project-title {
//     position: absolute;
//     top: 3rem;
//     right: 0;
//     bottom: 0;
//     left: 3rem;
//     border: 1px solid var(--light);
//     border-radius: 8px;
//     display: flex;
//     align-items: flex-end;
//     padding: 18px;
//     z-index: -1;
//     transition: .5s;
// }

// .project-item:hover .project-title {
//     background: var(--primary);
//     border-color: var(--primary);
// }

// .project-item .project-title h4 {
//     transition: .5s;
// }

// .project-item:hover .project-title h4 {
//     color: #FFFFFF;
// }

// .project-carousel .owl-nav {
//     margin-top: 25px;
//     display: flex;
//     justify-content: center;
// }

// .project-carousel .owl-nav .owl-prev,
// .project-carousel .owl-nav .owl-next {
//     margin: 0 12px;
//     width: 45px;
//     height: 45px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     color: var(--primary);
//     background: var(--light);
//     border-radius: 45px;
//     font-size: 22px;
//     transition: .5s;
// }

// .project-carousel .owl-nav .owl-prev:hover,
// .project-carousel .owl-nav .owl-next:hover {
//     background: var(--primary);
//     color: var(--light);
// }


// /*** Team ***/
// .team-item {
//     position: relative;
//     padding: 4rem 0;
// }

// .team-item img {
//     position: relative;
//     z-index: 2;
// }

// .team-item .team-text {
//     position: absolute;
//     top: 0;
//     right: 3rem;
//     bottom: 0;
//     left: 3rem;
//     padding: 15px;
//     border: 1px solid var(--light);
//     border-radius: 8px;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: space-between;
//     transition: .5s;
//     z-index: 1;
// }

// .team-item:hover .team-text {
//     background: var(--primary);
//     border-color: var(--primary);
// }

// .team-item .team-text h4 {
//     transition: .5s;
// }

// .team-item:hover .team-text h4 {
//     color: #FFFFFF;
// }

// .team-item .team-social .btn {
//     background: var(--light);
//     color: var(--primary);
// }

// .team-item:hover .team-social .btn {
//     background: #FFFFFF;
// }

// .team-item .team-social .btn:hover {
//     background: var(--primary);
//     color: var(--light);
// }


// /*** Testimonial ***/
// .testimonial-item {
//     position: relative;
//     text-align: center;
//     padding-top: 30px;
// }

// .testimonial-item .testimonial-text {
//     position: relative;
//     text-align: center;
// }

// .testimonial-item .testimonial-text .btn-square {
//     position: absolute;
//     width: 60px;
//     height: 60px;
//     top: -30px;
//     left: 50%;
//     transform: translateX(-50%);
// }

// .testimonial-item .testimonial-text::before {
//     position: absolute;
//     content: "";
//     bottom: -60px;
//     left: 50%;
//     transform: translateX(-50%);
//     border: 30px solid;
//     border-color: var(--light) transparent transparent transparent;
// }

// .testimonial-item .testimonial-text::after {
//     position: absolute;
//     content: "";
//     bottom: -59px;
//     left: 50%;
//     transform: translateX(-50%);
//     border: 30px solid;
//     border-color: #FFFFFF transparent transparent transparent;
// }

// .testimonial-carousel .owl-item img {
//     margin: 0 auto;
//     width: 100px;
//     height: 100px;
// }

// .testimonial-carousel .owl-dots {
//     margin-top: 25px;
//     display: flex;
//     align-items: flex-end;
//     justify-content: center;
// }

// .testimonial-carousel .owl-dot {
//     position: relative;
//     display: inline-block;
//     margin: 0 5px;
//     width: 30px;
//     height: 30px;
//     border: 1px solid var(--light);
//     border-radius: 30px;
//     transition: .5s;
// }

// .testimonial-carousel .owl-dot::after {
//     position: absolute;
//     content: "";
//     width: 16px;
//     height: 16px;
//     top: 6px;
//     left: 6px;
//     border-radius: 16px;
//     background: var(--light);
//     transition: .5s;
// }

// .testimonial-carousel .owl-dot.active {
//     border-color: var(--primary);
// }

// .testimonial-carousel .owl-dot.active::after {
//     background: var(--primary);
// }


// /*** Footer ***/
// .footer .btn.btn-link {
//     display: block;
//     margin-bottom: 5px;
//     padding: 0;
//     text-align: left;
//     color: var(--light);
//     font-weight: normal;
//     text-transform: capitalize;
//     transition: .3s;
// }

// .footer .btn.btn-link::before {
//     position: relative;
//     content: "\f105";
//     font-family: "Font Awesome 5 Free";
//     font-weight: 900;
//     margin-right: 10px;
// }

// .footer .btn.btn-link:hover {
//     color: var(--primary);
//     letter-spacing: 1px;
//     box-shadow: none;
// }

// .copyright {
//     color: var(--light);
//     background: #000B1C;
// }

// .copyright a {
//     color: var(--light);
// }

// .copyright a:hover {
//     color: var(--primary);
// }