@import '../../../styles/scss/index.scss';

/*** Navbar ***/
.navbar {
    position: relative !important;
    display: flex !important;
    flex-wrap: wrap !important;
    align-items: flex-end !important;
    justify-content: space-between !important;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.navbar-margin {
    display: flex;
    align-items: flex-end;
}
.bg-black{
    background-color: $raisinblack !important;
}
.text-common{
    color: $onyx;
    font-size: 20px;
    font-weight: 400;
}
.text-lite-product {
    color: $suvagrey;
    font-size: 18px;
    font-weight: 400;
  }