@import '../../../styles/scss/index.scss';

.contact-wdsInfotech-bg {
    background: $white;
    min-height: 110vh !important;
    align-items: center;
    justify-content: center;
}

.text-email{
    color: $outrageousorange;
    text-decoration: none;
}