$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);
// h1 {
//   font-size: 75px;
//   color: $onyx;
//   font-family: "Poppins", sans-serif;
// }
// h2 {
//   font-size: 55px;
//   color: $onyx;
//   font-family: "Poppins", sans-serif;
// }
// h3 {
//   font-size: 40px;
//   color: $onyx;
//   font-family: "Poppins", sans-serif;
// }
h4 {
  font-size: 26px;
  color: $scorpion;
  font-family: "Poppins", sans-serif;
}
h5 {
  font-size: 20px;
  color: $onyx;
  font-family: "Poppins", sans-serif;
}
p {
  font-size: 17px !important;
}
// h6 {
//   font-size: 20px;
//   color: $onyx;
//   font-family: "Poppins", sans-serif;
// }
// p {
//   font-size: 17px;
//   color: $onyx;
//   font-family: "Poppins", sans-serif;
// }
// span {
//   font-size: 15px;
//   color: $dimgray;
//   font-family: "Poppins", sans-serif;
// }
.btn-secondary{
  --bs-btn-hover-bg: 'none' !important;
}
.campus-button-oval {
  background-image: linear-gradient(10deg,
      $peachorange 10%,
      $cccalendartoday 100%);
  border: none !important;
  border-radius: 25px;
  margin: 1px 3px 2px 5px;
  padding: 5px 15px;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: $darkgreen !important;
  cursor: pointer;
  &:focus {
    outline: 0;
    box-shadow: none !important;
  }
  &:hover {
    background-image: linear-gradient(100deg,
    $cccalendartoday 10%,
    $peachorange 80%);
    color: $onyx !important;
  }
}
.campus-button-plain {
  background-color: $white !important;
  border: 1px solid  $peachorange !important;
  border-radius: 25px;
  margin: 1px 3px 2px 5px;
  padding: 5px 15px;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: $darkgreen !important;
  cursor: pointer;
  &:focus {
    outline: 0;
    box-shadow: none !important;
    background-color: $white !important;
  }
  &:hover {
    background-image: linear-gradient(100deg,
    $cccalendartoday 10%,
    $peachorange 80%);
    color: $onyx !important;
  }
}