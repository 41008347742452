@import '../../../styles/scss/index.scss';

/*** Navbar ***/
.navbar {
    position: relative !important;
    display: flex !important;
    flex-wrap: wrap !important;
    align-items: flex-end !important;
    justify-content: space-between !important;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.navbar-margin {
    display: flex;
    align-items: flex-end;
}
.log-mob {
    display: none;
}
.font-intro{
  color: $spacecadet;
  font-weight: 600;
}
.font-primary{
  color: $spacecadet !important;
}
// .active{
//   color: $spacecadet !important;
// }
.nav-link.active {
  color: $freespeechred !important;
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: $white !important;
  background-color: $peachorange !important;
}
.font-simple{
  color: $dimgray;
  font-weight: 300;
}
.font-padding{
  padding: 2px 3px;
}
.carousel-caption {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  text-align: start;
  z-index: 1;
  margin-top: 7rem;
}
.btn-primary{
  background-color: $spacecadet !important;
  border: none;
}
@media screen and (max-width: 480px) {
    .log-mob {
      display: block;
      background-color: transparent !important;
    }
    .carousel-caption-bottom {
      padding-bottom: 20rem !important;
      display: flex;
      align-items: center;
      justify-items: center;
    }
    .carousel-caption-top-text {
      padding-top: 5rem !important;
    }
    .mob-text-center-aligned {
      text-align: center !important;
    }
    .mobile-padding-web{
      margin: 1px 5px;
    }
  }
.min-image-profile{
  height: 355px !important;
}
  .footer {
    clear: both;
    position: relative;
    bottom: 0;
    // padding: 10px;
    background: $blackpearl !important;
  }
  
  .text-footer-header{
    color: $white;
    font-size: 20px;
    font-weight: 500;
  }
  .text-footer-know-campus{
    color: $white;
    font-size: 20px;
    font-weight: 500 !important;
  }
  .text-footer-desc{
    color: $white;
    font-size: 14px;
    font-weight: 400;
  }
  .btn-footer-icon{
    border: 1px solid $white;
    border-radius: 5px;
    padding: 3px 5px;
    margin: 2px;
    min-width: 40px !important;
    cursor: pointer;
    &:focus {
      outline: 0;
      box-shadow: none !important;
    }
    &:hover {
      border: 1px solid $pancho;
    }
  }
  .footer-wds-icon{
    color: $white;
  }
  .copyright{
    color: $white;
    font-weight: 300;
  }
  .text-copyright-mail{
    color: $white;
    font-weight: 400;
    &:hover {
      color: $whisper;
    }
  }
  .text-footer-abou-count{
    font-size: 35px;
    color: $darkturquoise;
    font-weight: 800;
  }
  .text-footer-abou-title{
    font-size: 25px;
    color: $white;
    font-weight: 700;
  }
  .welcome-wds{
    color: $spacecadet;
    font-weight: 300;
  }

// Hexa image Teams
.img-team {
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
}

.hexagon-gallery {
  margin: auto;
  margin-top: 50px;
  max-width: 1000px;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-auto-rows: 200px;
  grid-gap: 14px;
  padding-bottom: 50px;
}

.hex {
  display: flex;
  position: relative;
  width: 330px;
  height: 350px;
  background-color: #ffffff;
  // -webkit-clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  // clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}
.icon-linkdein{
  color: $linkedien;
  font-size: 24px;
  cursor: pointer;
}
.ayush-img{
  height: 180px;
  width: 200px;
  padding: 5px;
}
.printer-img{
  height: auto;
  width: 500px;
  padding: 5px;
}
.ai-text{
  color: $freespeechred !important;
}
.print-gif{
  height: 80% !important;
  width: 80% !important;
}
