@import '../../../styles/scss/index.scss';

.navbar {
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  // position: fixed !important;
  top: 0;
  // width:100%;
  // width: calc(100% - 300px);
  z-index: 1030;
  box-shadow: 0 0 1px $alto;
}

.nav-logo {
  color: $blackpearl;
  align-items: center;
  // margin: 20px !important;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  flex-grow: 1;
  margin: 0 10px !important;
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: left;
  padding-top: 10px;
  margin-right: 5px;
}

.nav-links {
  font-size: 16px;
  font-style: initial;
  color: $bayofmany;
  text-decoration: none;
  height: 100%;
  border-bottom: 1px solid transparent;
}

.nav-product-header {
  font-size: 16px;
  font-style: initial;
  color: $bayofmany;
}

.show>.nav-links:focus {
  color: $coral;
}

.show>.nav-links::after {
  color: $coral;
}

.nav-links:hover {
  color: $blackcurrant !important;
}

.fa-code {
  margin-left: 1rem;
}

.nav-item {
  line-height: 28px;
  // margin-right: 1.5rem;
  // margin-left: 1.5rem;
}

.nav-item:after {
  content: "";
  display: block;
  height: 0px;
  width: 0;
  background: $aliceblue;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
  width: 100%;
  background: $aliceblue;
}

.nav-icon {
  display: none;
}

.nav-icon:focus {
  color: $orange;
}

.nav-close-btn {
  font-size: 32px !important;
  margin-right: 5px;
  color: $dodgerblue;
}

.nav-open-btn {
  color: $darkcerulean;
}

.nav-center-align {
  display: flex;
  align-items: center;
  justify-content: center;
}


.nav-icon-link {
  color: $davysgrey !important;
  margin-right: 5px;
  font-size: .71em;
  margin-bottom: 1px !important;
}

.nav-icon-link-btn {
  color: $blackpearl;
  margin-right: 7px;
  font-size: .71em;
  margin-bottom: 1px
}

.nav-icon-toggle {
  color: $dimgray !important;
  margin-left: 5px;
  font-size: 14px !important;
  margin-bottom: 3px
}

.nav-text-header {
  color: $bayofmany;
  font-size: 15px;
  font-weight: 400;
}

@media screen and (max-width: 480px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-items: center;
    position: absolute;
    width: 100%;
    top: 75px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .wh-nav-dropdown {
    // margin-left: 35px;
    border-radius: 1px !important;
    background: $mintcream;
  }

  .icon-header-text {
    text-decoration: inherit;
    color: $orange;
    font-size: 15px;
    padding: 12px;
  }

  .nav-menu.active {
    background: $white;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    padding-right: 30px !important;
  }

  .nav-item {
    line-height: 40px !important;
    margin-left: 1px !important;
  }

  li {
    border-bottom: 2px solid $aliceblue;
    width: 100% !important;
  }

  .nav-item .active {
    color: $bayofmany;
    border: none;
  }

  .nav-links {
    padding: 1rem 0 1rem 0;
    width: 100%;
    display: table;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-60%, 30%);
    font-size: 1.8rem;
    cursor: pointer;
    color: $blackpearl;
  }
  .nav-item-login {
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .nav-icon-link-btn {
    color: $blackpearl !important;
    margin-right: 10px !important;
  }
}
